import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";

const TheGD = () => {
  return (
    <div
      className={`flex flex-col items-center justify-center   md:text-start  gap-4 border-b-2 container mx-auto py-4`}
    >
      <span className={`text-5xl font-bold py-4 mx-auto text-center md:py-8`}>
        The <span className="text-purple-600">Geeklab</span> Difference
      </span>
      <div className="text-xl  mx-auto pl-4 md:w-2/3">
        <p className="py-1">
          Combine technology training for employees with industry experts,
          mentors, and projects, for critical thinking that pushes innovation.
          Our proven upskilling system goes after success— relentlessly.
        </p>
      </div>
      <span className={`text-3xl font-bold py-4 mx-auto text-center md:py-8`}>
        Demonstrate proficiency with practical projects
      </span>
      <div className="text-xl  mx-auto pl-4 md:w-2/3">
        <p className="py-1">
          Projects are based on real-world scenarios and challenges, allowing
          you to apply the skills you learn to practical situations, while
          giving you real hands-on experience.
        </p>
      </div>
      <div>
        <p className="flex items-center gap-1">
          <FaRegCheckCircle color="#9C27B0" />
          Gain proven experience
        </p>
        <p className="flex items-center gap-1">
          <FaRegCheckCircle color="#9C27B0" />
          Retain knowledge longer
        </p>
        <p className="flex items-center gap-1">
          <FaRegCheckCircle color="#9C27B0" />
          Apply new skills immediately
        </p>
      </div>
    </div>
  );
};

export default TheGD;
