export const choiceMarketing = {
  choice: "Digital Marketing",
  firsttext:
    "Immediate understanding of customer behavior for agile strategy adjustments.",
  secondtext:
    "Budget-friendly approach compared to traditional marketing methods.",
  thirdtext:
    "Personalized interactions foster brand loyalty and lasting customer relationships.",
};

export const  choiceBackEnd = {
    choice:  "Back-End Development",
    firsttext:
      "Builds the backbone for smooth and efficient website and app operations.",
    secondtext:
      "Focus on scalable systems for optimal performance under varying workloads.",
    thirdtext:
      "Tackles complex technical challenges for robust solutions.",
  };
  
export const choiceFrontEnd = {
  choice: "Front-End Development",
  firsttext:
    "Crafts visually appealing and user-friendly interfaces for enhanced experiences.",
  secondtext:
    "Engages users directly through seamless navigation and interactions.",
  thirdtext:
    "Collaborates to turn design concepts into functional and interactive interfaces.",
};

export const choiceUiUx = {
  choice: "UI/UX Design",
  firsttext:
    "Focus on creating visually appealing and user-friendly digital products.",
  secondtext:
    "Allows for creativity in crafting intuitive and visually stunning designs.",
  thirdtext:
    "5Works closely with teams to transform ideas into user-centric, efficient products.",
};

export const fleshInfo = [
  {
    id : 1 , 
    
  }
]