import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./pages/About";
import ContactUs from "./components/ContactUs";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import UiUxCourse from "./pages/UiUxCourse";
import MarketingCourse from "./pages/MarketingCourse";
import FrontEnd from "./pages/FrontEnd";
import Backend from "./pages/Backend";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>

        <Route path="/" element={<HomePage />} />
        <Route path="/course/*">
          <Route path="marketing" element={<MarketingCourse />} />
          <Route path="ui-ux" element={<UiUxCourse />} />
          <Route path="FrontEnd" element={<FrontEnd />} />
          <Route path="Backend" element={<Backend />} />
        </Route>
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
        {/* <Route path="course/fullstack" element={<Fullstack />} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
