import React from "react";
import logo from "../assets/Logo.png";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { PiWhatsappLogo } from "react-icons/pi";

function Footer() {
  return (
    <div className="p-4 container m-auto flex flex-col justify-start my-3 md:my-5 ">
      <div className="flex items-center md:items-center flex-col text-violet-900">
        <img src={logo} alt="logo" className="h-5 mb-2" />
        <span>GeekLab, Sidi Bouzid, Tunisia </span>
        <span>+216 50 920 365</span>
        <span>contact@geeklab.tn</span>
      </div>
      <div className="flex items-center justify-center mt-4 mb-8 w-full text-3xl 	gap-6 md:gap-12 text-violet-900">
        <a
          className="cursor-pointer"
          href="https://www.facebook.com/profile.php?id=61554248371769"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF className="cursor-pointer" />
        </a>

        <a
          className="cursor-pointer"
          href="https://www.instagram.com/geeklabtn/"
          target="_blank"
          rel="noreferrer"
        >
          <BsInstagram />
        </a>
        <a
          className="cursor-pointer"
          href="https://wa.me/21650920365"
        >
          <PiWhatsappLogo />
        </a>
      </div>
    </div>
  );
}

export default Footer;
