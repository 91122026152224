import React from "react";
import image from "../../assets/workshop.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import CountUp from "react-countup";

const Workshop = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row container justify-center items-center md:gap-10">
      <div className="lg:w-1/2 flex flex-col justify-end items-end">
        <img
          src={image}
          alt="fromZeroImage"
          className="max-w-3/4 h-auto self-end"
        />
      </div>
      <div className="lg:w-1/2 p-8 lg:pl-24 flex flex-col justify-center items-center md:items-start">
        <h3 className="text-3xl font-bold mb-3 md:mb-8 text-center md:text-left">
          <span>The sky's the limit</span>
        </h3>
        <p className="text-center md:text-left mb-8 md:mb-8">
          It doesn’t stop there. You’ll be starting a career
          in an industry brimming with possibilities and potential. Take charge
          of your career now and you can go anywhere.
        </p>
        <div className="flex flex-col justify-center w-full">
          <div className="flex gap-5 font-bold">
            <div className="bg-teal-50 p-4 rounded  w-full h-24 mt-2 text-center">
              <span className="text-violet-900 text-xl font-extrabold pb-3">
                <CountUp start={0} end={120} duration={2} delay={1} />
              </span>
              <p>Practice Hours</p>
            </div>
            <div className="bg-teal-50 p-4 rounded  w-full h-24 mt-2 text-center">
              <span className="text-violet-900 text-xl font-extrabold">
                <CountUp start={0} end={12} duration={2} delay={1} />+
              </span>
              <p>Workshop</p>
            </div>
          </div>
          <div className="flex gap-5 font-bold">
            <div className="bg-teal-50 p-4 rounded w-full h-24 mt-2 text-center">
              <span className="text-violet-900 text-xl font-extrabold">
                <CountUp start={0} end={3} duration={2} delay={1} />
              </span>
              <p>Projects</p>
            </div>
            <div className="bg-teal-50 p-4 rounded w-full h-24 mt-2 text-center">
              <span className="text-violet-900 text-xl font-extrabold">
                <CountUp start={0} end={5} duration={2} delay={1} />+
              </span>
              <p>Additional Courses</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workshop;
