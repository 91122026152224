import React from "react";

const LogoList = ({logo}) => {
  return (
    <div className="w-full flex justify-center align-middle my-6 md:my-12 ">
    <div className="flex flex-wrap gap-7 justify-center align-middle w-4/5 h-1/2">
      {logo.map((logo, index) => (
        <div
          key={index}
          className="w-24 md:w-32 lg:w-48 md:h-32 flex items-center justify-center transition duration-300 ease-in-out transform hover:scale-110"
        >
          <img
            src={logo.src}
            alt={logo.name}
            className="w-full h-full object-contain"
            style={{
              filter: "grayscale(100%)", 
              transition: "filter 0.3s ease-in-out", 
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.filter = "grayscale(0%)"; 
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.filter = "grayscale(100%)"; 
            }}
          />
        </div>
      ))}
    </div>
    </div>
  );
};

export default LogoList;
