import React, { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";

const FleshInfo = ({title , p1 , p2}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [iconRotation, setIconRotation] = useState(0);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIconRotation(iconRotation === 0 ? 180 : 0);
  };

  const customStyle = {
    right: "-9px",
    bottom: "-6px",
  };

  const customStyle2 = {
    transform: `rotate(${iconRotation}deg)`,
    transition: "transform 0.3s ease",
  };
  return (
    <div style={{ maxWidth: "650px" }} className="">
      <div className="w-full border-b-2 md:border-2 border-violet-500 p-4 md:rounded-3xl text-violet-500">
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={toggleDropdown}
        >
          <div className="text-lg font-semibold">
          {title}
          </div>
          <BsChevronDown size={24} style={customStyle2} />
        </div>
      </div>
      {isDropdownOpen && (
        <div className="mt-4 pl-4 space-y-2 border-black relative">
          <p>{p1}</p>
          <p>{p2}</p>
        </div>
      )}
    </div>
  );
};

export default FleshInfo;
