import React, { useRef, useState } from "react";
import Modal from "react-modal";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "90%",
    width: "100%",
    height: "60%",
  },
};

const FilltheForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Fetch the form data
    const formData = new FormData(e.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const phoneNumber = formData.get("phoneNumber");
    const course = formData.get("course");
    const profession = formData.get("profession");
    const level = formData.get("level");

    // Email.js parameters
    const serviceID = "service_pvn7eo9";
    const templateID = "template_1svhs8p";
    const userID = "aAVdceistCACMvxxo";

    // Send email using Email.js
    emailjs
      .send(
        serviceID,
        templateID,
        {
          name,
          email,
          phoneNumber,
          course,
          profession,
          level,
        },
        userID
      )
      .then((response) => {
        toast.success(
          "Your message has been sent successfully.Welcome to the family ",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          }
        );
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        toast.error("Something went wrong. Please try again later!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
      });

    // Optionally, you can close the modal here
    closeModal();
  };

  // const form = useRef();
  const form = useRef();

  return (
    <div className=" items-center md:items-end flex justify-end md:border-b-2 border-violet-600 md:mt-14">
      {/* <div
        className="flex h-1/2 justify-center md:border-b-0 items-center text-3xl cursor-pointer hover:font-bold md:rounded-tl-3xl text-violet-600 border-violet-600 border-2 w-full md:w-1/3"
        onClick={openModal}
      >
        <span>Fill the form now</span>
      </div> */}

      <div>
        <div className="flex fixed bottom-0 right-0  " onClick={openModal}>
          <div className="bg-violet-600 text-4xl w-screen text-white text-center px-6 py-3  hover:bg-violet-700 focus:outline-none focus:bg-violet-700">
          سجّل معنا الآن
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Fill the Form Modal"
      >
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Fill the Form</h2>
          <form onSubmit={handleSubmit} ref={form}>
            <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-4 gap-2">
              <div className="mb-4 md:w-1/3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name:
                </label>
                <input
                  className="border-2 border-violet-600 rounded-md px-3 py-2 mt-1 w-full focus:outline-none focus:border-violet-400"
                  type="text"
                  id="name"
                  name="name"
                  required
                />
              </div>
              <div className="mb-4 md:w-1/3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email:
                </label>
                <input
                  className="border-2 border-violet-600 rounded-md px-3 py-2 mt-1 w-full focus:outline-none focus:border-violet-400"
                  type="email"
                  id="email"
                  name="email"
                  required
                />
              </div>
              <div className="mb-4 md:w-1/3">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number:
                </label>
                <input
                  className="border-2 border-violet-600 rounded-md px-3 py-2 mt-1 w-full focus:outline-none focus:border-violet-400"
                  type="text"
                  id="tel"
                  name="phoneNumber"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between md:items-start mb-4 gap-2">
              <div className="mb-4 md:w-1/3">
                <div className="flex flex-col md:flex-row md:justify-between md:items-start mb-4 gap-2">
                  <div className="mb-4 md:w-1/3">
                    <span className="block text-sm font-medium text-gray-700">
                      What do you want to study?
                    </span>
                    <div className="flex items-center mt-1">
                      <input
                        type="radio"
                        id="marketing"
                        name="course"
                        value="marketing"
                        className="border-violet-600 focus:ring-violet-400 h-4 w-4 text-violet-600"
                        required
                      />
                      <label
                        htmlFor="marketing"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Marketing
                      </label>
                    </div>
                    <div className="flex items-center mt-1">
                      <input
                        type="radio"
                        id="ui-ux"
                        name="course"
                        value="ui-ux"
                        className="border-violet-600 focus:ring-violet-400 h-4 w-4 text-violet-600"
                        required
                      />
                      <label
                        htmlFor="ui-ux"
                        className="ml-2 text-sm text-gray-700"
                      >
                        UI/UX
                      </label>
                    </div>
                    <div className="flex items-center mt-1">
                      <input
                        type="radio"
                        id="front-end"
                        name="course"
                        value="front-end"
                        className="border-violet-600 focus:ring-violet-400 h-4 w-4 text-violet-600"
                        required
                      />
                      <label
                        htmlFor="front-end"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Front-end
                      </label>
                    </div>
                    <div className="flex items-center mt-1">
                      <input
                        type="radio"
                        id="back-end"
                        name="course"
                        value="back-end"
                        className="border-violet-600 focus:ring-violet-400 h-4 w-4 text-violet-600"
                        required
                      />
                      <label
                        htmlFor="back-end"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Back-End
                      </label>
                    </div>
                  </div>
                  <div className="mb-4 md:w-1/3">
                    <span className="block text-sm font-medium text-gray-700">
                      What’s your proffession?
                    </span>
                    <div className="flex items-center mt-1">
                      <input
                        type="radio"
                        id="student"
                        name="profession"
                        value="student"
                        className="border-violet-600 focus:ring-violet-400 h-4 w-4 text-violet-600"
                        required
                      />
                      <label
                        htmlFor="student"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Student
                      </label>
                    </div>
                    <div className="flex items-center mt-1">
                      <input
                        type="radio"
                        id="employee"
                        name="profession"
                        value="employee"
                        className="border-violet-600 focus:ring-violet-400 h-4 w-4 text-violet-600"
                        required
                      />
                      <label
                        htmlFor="employee"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Employee
                      </label>
                    </div>
                    <div className="flex items-center mt-1">
                      <input
                        type="radio"
                        id="Unemployed"
                        name="profession"
                        value="Unemployed"
                        className="border-violet-600 focus:ring-violet-400 h-4 w-4 text-violet-600"
                        required
                      />
                      <label
                        htmlFor="Unemployed"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Unemployed
                      </label>
                    </div>
                  </div>
                  <div className="mb-4 md:w-1/3">
                    <span className="block text-sm font-medium text-gray-700">
                      Your level in English
                    </span>
                    <div className="flex items-center mt-1">
                      <input
                        type="radio"
                        id="beginner"
                        name="level"
                        value="beginner"
                        className="border-violet-600 focus:ring-violet-400 h-4 w-4 text-violet-600"
                        required
                      />
                      <label
                        htmlFor="beginner"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Beginner
                      </label>
                    </div>
                    <div className="flex items-center mt-1">
                      <input
                        type="radio"
                        id="intermediate"
                        name="level"
                        value="intermediate"
                        className="border-violet-600 focus:ring-violet-400 h-4 w-4 text-violet-600"
                        required
                      />
                      <label
                        htmlFor="intermediate"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Intermediate
                      </label>
                    </div>
                    <div className="flex items-center mt-1">
                      <input
                        type="radio"
                        id="advanced"
                        name="level"
                        value="advanced"
                        className="border-violet-600 focus:ring-violet-400 h-4 w-4 text-violet-600"
                        required
                      />
                      <label
                        htmlFor="advanced"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Advanced
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div 
              className="flex justify-end"
            >
              <button
                className="bg-violet-600 text-white px-6 py-4 rounded-md hover:bg-violet-700 focus:outline-none focus:bg-violet-700"
                type="submit"
              >
                Submit
              </button>
              <button
                className="bg-white text-violet-600 ml-4 px-6 py-4 rounded-md hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
        {/* <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input type="text" name="name" />
          <label>Email</label>
          <input type="email" name="email" />
          <label>Message</label>
          <textarea name="message" />
          <input type="submit" value="Send" />
        </form> */}
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default FilltheForm;
