import React from "react";
import CourseNavbar from "../components/courseNavbar/CourseNavbar";
import FleshInfoContainer from "../components/fleshInfo/FleshInfoContainer";
import LogoList from "../components/Logos/Logos";
import { logosCoursePage } from "../constants/logo";
import Aboutandskills from "../components/AboutAndSkills/Aboutandskills";
import Fazat from "../components/Fazat/Fazat";
import { choiceBackEnd } from "../constants/DummyData";
import WhyChoose from "../components/WhyChoose/WhyChoose";
import TheGD from "../components/TheGD/TheGD";
import FleshInfoItem from "../components/fleshInfo/FleshInfoItem";
import imageGIF from "../assets/gif3.gif";

const Backend = () => {
  const BackCoursePage = [
    { name: "node", src: require("../assets/logo/node.png") },
    { name: "mongodb", src: require("../assets/logo/mongodb.png") },
    { name: "express", src: require("../assets/logo/express.png") },
    { name: "git", src: require("../assets/logo/git.png") },

        ];      
  return (
    <div>
      <CourseNavbar />
      <div className="flex flex-col gap-6 container m-auto p-12 realtive">
  
        <FleshInfoItem
          title={"Introduction to Back End Development"}
          p1={"- Overview of server-side languages (e.g., Node.js)"}
          p2={"- Setting up a back end environment"}
        />
        <FleshInfoItem
          title={"Database Management"}
          p1={"- Introduction to databases (e.g.,MongoDB"}
          p2={"- CRUD operations and database design"}
        />
        <FleshInfoItem
          title={"Server-side Programming"}
          p1={"- Building APIs and handling HTTP requests"}
          p2={"- Authentication and authorization"}
        />
        <FleshInfoItem
          title={"Frameworks and Libraries"}
          p1={"- Using back end frameworks (e.g., Express)"}
          p2={"- Incorporating third-party libraries"}
        />
        <FleshInfoItem
          title={"Testing and Debugging"}
          p1={"- Writing unit tests"}
          p2={"- Debugging techniques"}
        />
        <FleshInfoItem
          title={"Projects"}
          p1={"- Developing a complete back end system"}
          p2={"- Presentations and evaluations"}
        />
     
        <div
          className="absolute top-1/5 left-2/3 w-1/3 hidden md:block"
          style={{
            zIndex: -1,
          }}
        >
          <img src={imageGIF} alt="" />
        </div>
      </div>
      <LogoList logo={BackCoursePage} />
      <Aboutandskills pargraph={'Back-end developers manage server-side functionality, ensuring smooth web application operations. This Career Path will teach you the technologies you need to do just that.'}
        s1={'Design and manage efficient databases'}
        s2={'Use languages like Java or Python for robust applications'}
        s3={'Integrate and manage Application Programming Interfaces (APIs)'}
        s4={'Implement measures to protect web applications'}
      />
      <Fazat />
      <WhyChoose
        choice={choiceBackEnd?.choice}
        firsttext={choiceBackEnd?.firsttext}
        secondtext={choiceBackEnd?.secondtext}
        thirdtext={choiceBackEnd?.thirdtext}
      />
      <TheGD />
    </div>
  );
};

export default Backend;
