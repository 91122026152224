import React from "react";

const WhyChoose = ({ choice, firsttext, secondtext, thirdtext }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center   md:text-start  gap-4 border-b-2 container mx-auto py-4`}
    >
      <span className={`text-3xl font-bold py-4 mx-auto text-center md:py-8`}>
        Why Choose <span className=" text-violet-600">{choice}</span> ?
      </span>
      <div className="text-xl  mx-auto pl-4">
        <p className="py-1">- {firsttext}</p>
        <p className="py-1">- {secondtext}</p>
        <p className="py-1">- {thirdtext}</p>
      </div>
    </div>
  );
};

export default WhyChoose;
