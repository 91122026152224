import React from "react";
import { BsCheck } from "react-icons/bs";

const Aboutandskills = ({pargraph , s1 ,s2 ,s3, s4}) => {
  return (
    <div className="flex flex-col lg:flex-row container w-full  text-gray-800 m-auto 	leading-4">
      <div className="m-3 w-full lg:w-2/3 lg:pr-60">
        <h1 className="text-2xl font-semibold  my-4 ">About this skill path </h1>
        <p className="text-xl font-light space-x-5 leading-10">
         {pargraph}
        </p>
      </div>
      <div className="m-3 w-full lg:w-1/3">
        <h1 className="text-2xl font-semibold  my-4 ">Skills you’ll gain</h1>
        <ul className="text-xl  font-light leading-10">
            <li className="flex items-center"><BsCheck size={24}/>{s1}</li>
            <li className="flex items-center"><BsCheck size={24}/>{s2}</li>
            <li className="flex items-center"><BsCheck size={24}/>{s3}</li>
            <li className="flex items-center"><BsCheck size={24}/>{s4}</li>
        </ul>
      </div>
    </div>
  );
};

export default Aboutandskills;
