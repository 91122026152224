import React from "react";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

function Sidebar({ isOpen, toggleMenu }) {

  if (isOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  return (
    <div
      className={`bg-gradient-sidebar text-white w-2/3 h-screen fixed top-0 right-0 transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <style>
        {`
          .bg-gradient-sidebar {
            background: linear-gradient(270deg, rgba(50, 0, 116, 1) 0%, rgba(191, 143, 253, 1) 403.28%);
            z-index: 1000;
          }
        `}
      </style>
      <div className="flex justify-end px-5 py-2 text-2xl text-center
      ">
        <FaTimes className="text-xl cursor-pointer" onClick={toggleMenu} />
      </div>
      <ul className="flex-col text-center mt-10  w-full ">
        <li className="mb-5 text-2xl font-bold 
        
          hover:underline
        
        ">
          <Link to="/" onClick={toggleMenu}>
            Home
          </Link>
        </li>
        <li
          className="
          mb-5 text-2xl font-bold hover:underline
        "
        >
          <Link to="/course/marketing" onClick={toggleMenu}>
            Courses
          </Link>
        </li>
        {/* <li>
          <Link to="/about" onClick={toggleMenu}>
            About
          </Link>
        </li> */}
      </ul>
      <div className="absolute bottom-0 left-0 w-full mb-5 ml-5">
        {/* <Link
          to="/contact"
          className="bg-blue-600 text-white py-2 px-4 rounded-full font-bold text-sm block"
          onClick={toggleMenu}
        >
          Contact Us
        </Link> */}
      </div>
    </div>
  );
}

export default Sidebar;
