import React from "react";
import CourseNavbar from "../components/courseNavbar/CourseNavbar";
import LogoList from "../components/Logos/Logos";
import { logosCoursePage } from "../constants/logo";
import Aboutandskills from "../components/AboutAndSkills/Aboutandskills";
import Fazat from "../components/Fazat/Fazat";
import { choiceFrontEnd } from "../constants/DummyData";
import WhyChoose from "../components/WhyChoose/WhyChoose";
import TheGD from "../components/TheGD/TheGD";
import FleshInfoItem from "../components/fleshInfo/FleshInfoItem";
import imageGIF from "../assets/gif3.gif";

const FrontEnd = () => {

  const FrontCoursePage = [
    { name: "html", src: require("../assets/logo/html.png") },
    { name: "css", src: require("../assets/logo/css.png") },
    { name: "javascript", src: require("../assets/logo/javascript.png") },
    { name: "react", src: require("../assets/logo/react.png") },
        ];       

  return (
    <div>
      <CourseNavbar />
      <div className="flex flex-col gap-6 container m-auto p-12 relative">
        

        
        <FleshInfoItem
          title={"Introduction to Front End Development"}
          p1={"- Basics of HTML, CSS, and JavaScript"}
          p2={"- Setting up a development environment"}
        />
        <FleshInfoItem
          title={"Responsive Design"}
          p1={"- Media queries and responsive layouts"}
          p2={"- Mobile-first development"}
        />
        <FleshInfoItem
          title={"JavaScript Fundamentals"}
          p1={"- Variables, loops, and functions"}
          p2={"- DOM manipulation"}
        />
        <FleshInfoItem
          title={"Front End Framework"}
          p1={"- Introduction to popular frameworks (e.g., React )"}
          p2={"- Building interactive web applications"}
        />
        <FleshInfoItem
          title={"Version Control and Deployment"}
          p1={"- Using Git for version control"}
          p2={"- Deploying a website to hosting services"}
        />
        <FleshInfoItem
          title={"Projects"}
          p1={"- Applying learned skills to develop a web projects"}
          p2={"- Presentations and feedbacks"}
        />

        <div
          className="absolute top-1/5 left-2/3 w-1/3 hidden md:block"
          style={{
            zIndex: -1,
          }}
        >
          <img src={imageGIF} alt="" />
        </div>
      </div>
      <LogoList logo={FrontCoursePage} />
      <Aboutandskills pargraph={'Front-end developers work closely with designers to make websites beautiful, functional, and fast. This Career Path will teach you the technologies you need to do just tha'}
      s1={'Structure and style web content with HTML/CSS'}
      s2={'Create dynamic and interactive user interfaces with JavaScript'}
      s3={'Design websites that adapt to various devices'}
      s4={'Use tools like Git for collaborative code management'}
      />
      <Fazat />
      <WhyChoose
        choice={choiceFrontEnd?.choice}
        firsttext={choiceFrontEnd?.firsttext}
        secondtext={choiceFrontEnd?.secondtext}
        thirdtext={choiceFrontEnd?.thirdtext}
      />
      <TheGD />
    </div>
  );
};

export default FrontEnd;
