import React from "react";
import CountUp from "react-countup";

const Discount = () => {
  return (
    <div className="flex flex-col md:flex-row container justify-center   md:gap-10 md:h-96">
      <div className="flex flex-col md:w-1/2 h-full justify-between items-center text-center">
        {/* First box */}
        <div className="w-full h-2/5 p-5 border border-gray-300 rounded-3xl">
          <span className="text-5xl font-bold">
            <CountUp start={0} end={100} duration={2} delay={0.5} /> %
          </span>
          <p className="text-2xl font-bold">Practical</p>
        </div>

        <div className="mt-10"></div>

        <div className="w-full h-2/5 p-5 border border-gray-300 rounded-3xl mb-10 md:mb-0">
          <span className="text-2xl md:text-4xl font-bold">
          Best Price Guarantee 
          </span>
          <p className="text-xl md:text-2xl font-bold">
            You won't find better
            value in the marketplace.
          </p>
        </div>
      </div>

      <div className="flex-1 w-full rounded-3xl border-2 border-gray-300  h-full">
        <button className="h-1/6 rounded-3xl mx-3 my-8 p-3 text-black border-emerald-300 bg-violet-200 transition duration-300 ease-in-out transform hover:scale-110">
          <span className="text-base">Get Started</span>
        </button>
        <p className="w-full p-10 lg:leading-loose">
          Our courses center employs cutting-edge professional tools and
          software, ensuring the most efficient and effective methods for your
          education. We are committed to providing you with the highest quality
          learning experience through state-of-the-art resources.
        </p>
      </div>
    </div>
  );
};

export default Discount;
