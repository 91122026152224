import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Logo from "../../assets/Logo.png";
import Sidebar from "./Sidebar"; // Import the Sidebar component

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollTobottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <div>
      {/* Old Navbar */}

      <nav className="text-black px-5 py-2 flex justify-between items-center m-auto container font-semibold ">
        <Link to="/" className="flex items-center">
          <img src={Logo} alt="Logo" className="h-5" />
        </Link>
        <div className="md:flex space-x-4 hidden ">
          <ul className={`md:flex md:space-x-4 `}>
            <li>
              <Link to="/" >
                Home
              </Link>
            </li>
            <li>
              <Link to="/course/marketing">
                Courses
              </Link>
            </li>
            {/* <li>
              <Link to="/about" >
                About
              </Link>
            </li> */}
          </ul>
        </div>
        <div className="md:hidden cursor-pointer" onClick={toggleMenu}>
          <FaBars />
        </div>
        {/* Conditional rendering of "Contact Us" link */}
        {!isOpen && (
          <div
            
            className={`bg-teal-50 text-black border border-black py-2 px-4 rounded-full  text-sm   hidden md:block`}
            onClick={scrollTobottom}
          >
            Contact Us
          </div>
        )}
      </nav>
      <Sidebar isOpen={isOpen} toggleMenu={toggleMenu} />
    </div>
  );
}

export default Navbar;
