export const logosHomePage = [
    { name: "html", src: require("../assets/logo/html.png") },
    { name: "javascript", src: require("../assets/logo/javascript.png") },
    { name: "react", src: require("../assets/logo/react.png") },
    { name: "css", src: require("../assets/logo/css.png") },
    { name: "figma", src: require("../assets/logo/figma.png") },
    { name: "upwork", src: require("../assets/logo/upwork.png") },
    { name: "express", src: require("../assets/logo/express.png") },
    { name: "node", src: require("../assets/logo/node.png") },
    { name: "linkedin", src: require("../assets/logo/linkedin.png") },
    { name: "mongodb", src: require("../assets/logo/mongodb.png") },
    { name: "git", src: require("../assets/logo/git.png") },
    { name: "digital-marketing", src: require("../assets/logo/digital-marketing.png") },
        ];


export const logosCoursePage = [
    { name: "html", src: require("../assets/logo/html.png") },
    { name: "figma", src: require("../assets/logo/figma.png") },
    { name: "upwork", src: require("../assets/logo/upwork.png") },
    { name: "express", src: require("../assets/logo/express.png") },
    { name: "digital-marketing", src: require("../assets/logo/digital-marketing.png") },
        ];       