import React from 'react'
import FazaItem from './FazaItem'
import clockImage from '../../assets/clock.png'
import Carrier from '../../assets/Carrier.png'
import Certificate from '../../assets/Certificate.png'


const Fazat = () => {
  return (
    <div className='container m-auto  border-b-2 border-t-2 flex flex-col md:flex-row justify-between md:items-center px-12 py-9 my-9 gap-6'>
      <FazaItem text1={"Skill level"} text2={"Advanced"} srcImage={Carrier}/>
      <FazaItem text1={"Time to complete"} text2={"120 Hours"} srcImage={clockImage}/>
      <FazaItem text1={"Certificate of completion"} text2={"Yes"} srcImage={Certificate}/>
    </div>
  )
}

export default Fazat
