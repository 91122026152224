import React from "react";
import { Link, useLocation } from "react-router-dom";

const CourseNavbar = () => {
  const location = useLocation();
  const currentPathname = location.pathname;

  const link = [
    {
      name: "Marketing",
      path: "/course/marketing",
    },
    {
      name: "UI/UX",
      path: "/course/ui-ux",
    },
    {
      name: "FrontEnd",
      path: "/course/FrontEnd",
    },
    {
      name: "BackEnd",
      path: "/course/Backend",
    },
  ];

  const customStyle = {
    borderTop: "0.5px solid #000",
    borderBottom: "0.5px solid #000",
    background:
      "linear-gradient(90deg, rgba(17, 1, 119, 0.70) 0%, rgba(17, 1, 119, 0.60) 100%)",
    backdropFilter: "blur(2.5px)",
  };

  return (
    <div style={customStyle} className="w-full h-16 flex justify-center  md:justify-start items-center  md:gap-8  md:p-8 text-white">
      {link.map((item, index) => {
        const isCurrent = currentPathname === item.path;
        return (
          <div
            key={index}
            className={`w-14 md:w-40 pb-2 cursor-pointer mr-5 ml-5 flex justify-center ${isCurrent ? 'text-orange-400 border-b border-orange-400 font-bold' : 'hover:text-orange-400 hover:border-b border-orange-400'}`}
          >
            <ul>
              <li>
                <Link to={item.path}>{item.name}</Link>
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default CourseNavbar;
