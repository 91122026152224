import React from "react";
import CourseNavbar from "../components/courseNavbar/CourseNavbar";
import { logosCoursePage } from "../constants/logo";
import LogoList from "../components/Logos/Logos";
import Aboutandskills from "../components/AboutAndSkills/Aboutandskills";
import Fazat from "../components/Fazat/Fazat";
import WhyChoose from "../components/WhyChoose/WhyChoose";
import { choiceMarketing } from "../constants/DummyData";
import TheGD from "../components/TheGD/TheGD";
import FleshInfoItem from "../components/fleshInfo/FleshInfoItem";
import imageGIF from "../assets/gif.gif";

const MarketingCourse = () => {
  const MarketingCoursePage = [
    { name: "digital-marketing", src: require("../assets/logo/digital-marketing.png") },
    {name: "facebook-ads", src: require("../assets/logo/meta.jpg")},
    {name: "google-ads", src: require("../assets/logo/googleAnaltyc.jpg")},
        ];  
  return (
    <div>
      <CourseNavbar />
      <div className="flex flex-col gap-6 container m-auto p-12 relative">
        <FleshInfoItem
          title={"Introduction to Marketing"}
          p1={"- Understanding marketing fundamentals"}
          p2={"- Market research and analysis"}
        />
        <FleshInfoItem
          title={"Digital Marketing Basics"}
          p1={"- Social media marketing marketing fundamentals"}
          p2={"- Email marketing essentials"}
        />
        <FleshInfoItem
          title={"Content Creation and Strategy"}
          p1={"- Content marketing principles"}
          p2={"- Developing a content strategy"}
        />
        <FleshInfoItem
          title={"SEO and Analytics"}
          p1={"- Introduction to Google Ads and Facebook Ads"}
          p2={"- Budgeting and targeting strategies"}
        />
        <FleshInfoItem
          title={"Paid Advertising"}
          p1={"- Introduction to Google Ads and Facebook Ads"}
          p2={"- Budgeting and targeting strategies"}
        />
        <FleshInfoItem
          title={"Projects and Presentations"}
          p1={"- Applying learned concepts to create a marketing campaign"}
          p2={"- Presentations and feedbacks"}
        />
        <div
          className="absolute top-1/5 left-2/3 w-1/3 hidden md:block"
          style={{
            zIndex: -1,
          }}
        >
          <img src={imageGIF} alt="" />
        </div>
      </div>
      <LogoList logo={MarketingCoursePage} />
      <Aboutandskills pargraph={'Digital marketers drive online visibility and sales through strategic planning and creative communication. This Career Path will teach you the technologies you need to do just that.'} 
        s1={'Interpret data for optimized marketing strategies'}
        s2={'Enhance online visibility through effective SEO'}
        s3={'Leverage social platforms for brand promotion'}
        s4={'Create compelling content for audience engagement'}
      />
      <Fazat />
      <WhyChoose
        choice={choiceMarketing?.choice}
        firsttext={choiceMarketing?.firsttext}
        secondtext={choiceMarketing?.secondtext}
        thirdtext={choiceMarketing?.thirdtext}
      />
      <TheGD />
    </div>
  );
};

export default MarketingCourse;
