import React from "react";

const FazaItem = ({text1 , text2 , srcImage}) => {
  return (
    <div className="flex gap-8 items-center">
        <img
            src={srcImage}
            alt="logo"
            className="w-14 h-14"
        />
      <div className="flex flex-col justify-around gap-6">
        <span className="text-2xl font-light">{text1}</span>
        <span className="text-2xl font-semibold">{text2}</span>
      </div>
    </div>
  );
};

export default FazaItem;
