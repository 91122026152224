import React from 'react'
import FormZero from '../components/FromZero/FormZero'
import Workshop from '../components/Workshop/Workshop'
import LogoList from '../components/Logos/Logos'
import Discount from '../components/Discount/Discount'
import FilltheForm from '../components/FilltheForm/FilltheForm'
import { logosHomePage } from '../constants/logo'


const HomePage = () => {
  return (
    <div className='container m-auto'>
      <FormZero/>
      <Workshop/>
      <LogoList logo={logosHomePage}  />
      <Discount/>
      <FilltheForm/>
    </div>
  )
}

export default HomePage
