import React from "react";
import CourseNavbar from "../components/courseNavbar/CourseNavbar";
import FleshInfoContainer from "../components/fleshInfo/FleshInfoContainer";
import LogoList from "../components/Logos/Logos";
import { logosCoursePage } from "../constants/logo";
import Aboutandskills from "../components/AboutAndSkills/Aboutandskills";
import Fazat from "../components/Fazat/Fazat";
import { choiceUiUx } from "../constants/DummyData";
import WhyChoose from "../components/WhyChoose/WhyChoose";
import TheGD from "../components/TheGD/TheGD";
import FleshInfoItem from "../components/fleshInfo/FleshInfoItem";
import imageGIF from "../assets/gif2.gif";

const UiUxCourse = () => {
  const UiUxCoursePage = [
    { name: "figma", src: require("../assets/logo/figma.png") },
    { name: "adxd", src: require("../assets/logo/adXD.jpg") },
        ];
  return (
    <div>
      <CourseNavbar />
      <div className="flex flex-col gap-6 container m-auto p-12 relative">
        <FleshInfoItem
          title={"Introduction to UI/UX Design"}
          p1={"- Basics of user interface and experience"}
          p2={"- Design thinking principles"}
        />
        <FleshInfoItem
          title={"User Research and Persona Creation"}
          p1={"- Conducting user research"}
          p2={"- Creating user personas"}
        />
        <FleshInfoItem
          title={"Wireframing and Prototyping"}
          p1={"- Using design tools for wireframing"}
          p2={"- Prototyping basics"}
        />
        <FleshInfoItem
          title={"Visual Design Principles"}
          p1={"- Color theory and typography"}
          p2={"- Creating visually appealing designs"}
        />
        <FleshInfoItem
          title={"Interaction Design"}
          p1={"- Animation and micro-interactions"}
          p2={"- Responsive design principles"}
        />
        <FleshInfoItem
          title={"Projects"}
          p1={"- Applying learned principles to create a UI/UX projects"}
          p2={"- Presentations and critique"}
        />
              <div
          className="absolute top-1/5 left-2/3 w-1/3 hidden md:block"
          style={{
            zIndex: -1,
          }}
        >
          <img src={imageGIF} alt="" />
        </div>
      </div>
      <LogoList logo={UiUxCoursePage} />
      <Aboutandskills pargraph={'UI/UX designers focus on visual elements, crafting layouts and icons for digital products and enhancing overall user experience, prioritizing intuitive interactions . This Career Path will teach you the technologies you need to do just that.'}
      s1={'Create visual concepts for testing'}
      s2={'Understand user needs to inform design decisions'}
      s3={'Utilize design tools for creating interfaces'}
      s4={'Conduct tests to optimize user experiences'}
      />
      <Fazat />
      <WhyChoose
        choice={choiceUiUx?.choice}
        firsttext={choiceUiUx?.firsttext}
        secondtext={choiceUiUx?.secondtext}
        thirdtext={choiceUiUx?.thirdtext}
      />
      <TheGD />
    </div>
  );
};

export default UiUxCourse;
