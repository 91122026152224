import React from "react";
import image from "../../assets/fromZeroImage1.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const FormZero = () => {
  const customFontStyle = {
    fontFamily: "Open Sans Condensed, sans-serif",
  };
  return (
    <div className="flex flex-col md:flex-row container justify-center items-center md:gap-10">
      <div className="lg:w-1/2 p-8 lg:pl-24 flex flex-col justify-center items-center md:items-start ">
        <h1 className="text-4xl md:text-6xl font-bold mb-3 " style={customFontStyle}>
          <span>From </span>
          <span className="text-violet-500">zero </span>
          <span>to </span>
          <span className="text-violet-500">hero</span>
        </h1>
        <h2 className="text-3xl md:text-5xl font-bold mb-6" style={customFontStyle}>
          Learn, Create, Excel
        </h2>
        <p className=" text-gray-500 text-center text-lg md:my-8 md:text-left">
          Discover the journey from novice to expert at our bootcamp, Join us to
          unleash your creative potential.
        </p>
        <Link to="/course/marketing">
          <div>
            <button className="bg-violet-500 text-white border py-5 px-5 rounded-full font-bold text-sm mt-5 flex gap-4
              hover:bg-violet-600 transition duration-300 ease-in-out
            ">
              Discover Courses
              <AiOutlineArrowRight className="text-xl" />
            </button>
          </div>
        </Link>
      </div>
      <div className="lg:w-1/2 p-4 flex flex-col justify-end items-end">
        <img
          src={image}
          alt="fromZeroImage"
          className="max-w-3/4 h-auto self-end"
        />
      </div>
    </div>
  );
};

export default FormZero;
